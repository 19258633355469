import { persistStore, persistReducer } from 'redux-persist';
import { baseApi } from 'store/services/base';
import storage from 'redux-persist/lib/storage';
import authReducer from 'store/slices/auth';
import drawerReducer from 'store/slices/drawer';
import { toast } from 'react-toastify';

import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action?.payload?.data?.ErrorMessage) {
      toast.error(action?.payload?.data?.ErrorMessage);
    } else {
      toast.error(`An error ocurred. Status code: ${action?.payload.status}`);
    }
  }

  return next(action);
};

const getPersistConfig = (key: string) => ({ key, storage });

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: persistReducer(getPersistConfig('auth'), authReducer),
  drawer: drawerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(baseApi.middleware)
      .concat(rtkQueryErrorLogger),

  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
