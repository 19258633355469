import { ReactComponent as CleantrackerSvg } from 'assets/svg/login/cleantracker-logo.svg';
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';

interface Props {
  open: boolean;
}

export const FullScreenLoader = ({ open }: Props) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        backgroundColor: 'rgba(255,255,255, 0.97)',
      }}
      open={open}
    >
      <Stack alignItems='center' spacing='120px'>
        <Box width='200px'>
          <CleantrackerSvg />
        </Box>
        <Box>
          <CircularProgress color='primary' size='80px' />
          <Typography mt={3} color='#7D7D7D' fontSize='14px'>
            Please wait...
          </Typography>
        </Box>
      </Stack>
    </Backdrop>
  );
};
