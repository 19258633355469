import { TextField, Box, TextFieldProps, InputLabel } from '@mui/material';

const CustomInput = (props: TextFieldProps) => {
  const { label, ...rest } = props;

  return (
    <Box>
      {label && (
        <InputLabel
          {...props.InputLabelProps}
          sx={{ ...props.InputLabelProps?.sx, textAlign: 'left' }}
          disabled={rest.disabled}
        >
          {label}
        </InputLabel>
      )}

      <TextField fullWidth {...rest} variant='outlined' />
    </Box>
  );
};

export default CustomInput;
