import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { revertAll } from 'store/actions';

export const CACHE_TAGS = [
  'Audit',
  'AuditArea',
  'AuditTemplate',
  'AuditMark',
  'AuditScorecard',
  'Cleaning',
  'CleaningActivity',
  'CleaningPoint',
  'CleaningPointItem',
  'CleaningSchedule',
  'CleaningScheduleItem',
  'ComplianceItem',
  'ComplianceResponse',
  'ComplianceTemplate',
  'Contract',
  'ContractItem',
  'CorrectiveAction',
  'DataImport',
  'Frequency',
  'IssueType',
  'Location',
  'MaintenanceRequest',
  'MaintenanceRequestItem',
  'Note',
  'Organization',
  'Periodical',
  'PeriodicalItem',
  'Portfolio',
  'PortfolioItem',
  'QrCode',
  'Report',
  'ReportSchedule',
  'Schedule',
  'Settings',
  'Shift',
  'ShiftActivity',
  'ShiftBreak',
  'ShiftItem',
  'ShiftSchedule',
  'ShiftScheduleItem',
  'Site',
  'SiteActivity',
  'SiteAudit',
  'SiteUpdate',
  'SiteUpdateItem',
  'SiteDocument',
  'SiteDocumentItem',
  'SurveyPoint',
  'SurveyPointItem',
  'SurveyTemplate',
  'SurveyTemplateItem',
  'SurveyResponse',
  'SurveyResponseItem',
  'SurveyType',
  'TakeFive',
  'TakeFiveResponse',
  'Task',
  'TaskCard',
  'TaskCardItem',
  'TaskTemplate',
  'User',
  'NonAttendance',
] as const;

export const BASE_URL = process.env.REACT_APP_BACKEND;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = sessionStorage.getItem('token');

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (!result.error) {
    return {
      data: result.data ?? null,
      meta: result.meta,
    };
  }

  if (
    (result.error && result.error.status === 401) ||
    (result.error && result.error.status === 'FETCH_ERROR')
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = sessionStorage.getItem('refreshToken');

        const refreshResult: any = await baseQuery(
          {
            url: `Auth/RefreshToken?refreshToken=${refreshToken}`,
            method: 'POST',
            responseHandler: 'text',
          },
          api,
          extraOptions,
        );

        if (refreshResult.data && refreshResult.data !== 'null') {
          sessionStorage.setItem('token', refreshResult.data);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(baseApi.util.resetApiState());
          api.dispatch(revertAll());
          sessionStorage.clear();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: CACHE_TAGS,
});
