import { createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

import FieldworkBold from 'assets/fonts/Fieldwork-Geo-Bold.woff2';
import FieldworkDemibold from 'assets/fonts/Fieldwork-Geo-Demibold.woff2';
import FieldworkRegular from 'assets/fonts/Fieldwork-Geo-Regular.woff2';
import FieldworkLight from 'assets/fonts/Fieldwork-Geo-Light.woff2';

export let theme = createTheme({});

theme = createTheme({
  palette: {
    primary: {
      main: '#FF5100',
    },
    brand: {
      orange: '#FF5100',
      orangeLight: '#F5DBC9',
      orangeHover: '#FF7F44',
      purple: '#3A1997',
      purpleDark: '#240C66',
    },
    grayscale: {
      100: '#FFFFFF',
      200: '#F2F2F2',
      300: '#A8A8A8',
      400: '#7D7D7D',
      500: '#333333',
    },
    success: {
      main: '#67bc4a',
    },

    error: {
      main: '#F25F4B',
      100: '#E0B3B2',
      200: '#FFF4F4',
    },
  },
  typography: () => ({
    fontFamily: ['Fieldwork', 'Poppins', 'sans-serif'].join(','),

    h1: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '36px',
      lineHeight: '36px',
      letterSpacing: '0.25px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.25px',
      },
    },
    h2: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '25px',
      lineHeight: '25px',
      letterSpacing: '0.5px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
      },
    },

    subtitle1: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '17px',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      fontWeight: 600,
      color: '#7D7D7D',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    subtitle2: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    body1: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      fontWeight: 300,
    },
    body2: {
      fontFamily: 'Fieldwork, sans-serif',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },

    button: {
      fontFamily: 'Fieldwork, sans-serif',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    caption: {
      fontFamily: 'Fieldwork, sans-serif',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  }),

  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Fieldwork';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('Fieldwork'), local('Fieldwork-Light'), url(${FieldworkLight}) format('woff2');
          },
          @font-face {
            font-family: 'Fieldwork';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Fieldwork'), local('Fieldwork-Regular'), url(${FieldworkRegular}) format('woff2');
          }
   
          @font-face {
            font-family: 'Fieldwork';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('Fieldwork'), local('Fieldwork-Demibold'), url(${FieldworkDemibold}) format('woff2');
          }

          @font-face {
            font-family: 'Fieldwork';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Fieldwork'), local('Fieldwork-Bold'), url(${FieldworkBold}) format('woff2');
          }

          ::-webkit-scrollbar {
            width: 7px !important;
          }
          
          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1 !important;
          }
          
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #A8A8A8 !important;
            border-radius: 4px;
          }
          
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
          }

          h1, h2, h3, h4, h5, h6, p {
            margin: 0;
          }
        `,
    },

    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
          color: theme.palette.grayscale[500],
          marginBottom: '4px',

          '&.Mui-disabled': {
            color: theme.palette.grayscale[300],
          },
        }),
        asterisk: {
          color: '#FF9292',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body1,
          padding: '12px 14px',
          lineHeight: '24px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.grayscale[500],
          color: theme.palette.grayscale[500],
          borderRadius: '8px',
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineColor: 'transparent',

          '& fieldset': {
            border: 'none',
          },

          '&.Mui-focused': {
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineColor: theme.palette.brand.orange,
            border: '1px solid transparent',
          },

          '&.Mui-error': {
            borderColor: theme.palette.error.main,
          },

          '&.Mui-disabled': {
            borderColor: theme.palette.grayscale[300],
            color: theme.palette.grayscale[300],
          },

          [theme.breakpoints.down('lg')]: {
            padding: '8px 12px',
            lineHeight: '16px',
          },
        }),

        focused: ({ theme }) => ({
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: theme.palette.brand.orange,
        }),

        input: () => ({
          padding: 0,
          '&:-webkit-autofill': {
            transition: 'background-color 5000s ease-in-out 0s',
            backgroundColor: 'white !important',
          },
        }),
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grayscale[400],

          '&.Mui-checked': {
            color: theme.palette.brand.orange,
          },
        }),
      },
    },

    MuiStepper: {
      styleOverrides: {
        root: {
          rowGap: '8px',
        },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          display: 'flex',
          alignItems: ' center',
        },

        label: ({ theme }) => ({
          fontWeight: 600,
          color: theme.palette.grayscale[400],
          '&.Mui-active': {
            display: 'inline',
            fontWeight: 600,
          },
        }),
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-active': {
            color: theme.palette.brand.orange,
          },
        }),
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 20,
          padding: 0,
        },

        switchBase: ({ theme }) => ({
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',

          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#fff',
              borderWidth: '1px',
              borderColor: theme.palette.brand.orange,
              borderStyle: 'solid',
            },

            '& .MuiSwitch-thumb': {
              backgroundColor: theme.palette.brand.orange,

              opacity: 1,
              border: 0,
            },

            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: theme.palette.grayscale[200],
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              backgroundColor: '#fff',
            },
          },

          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#fff',
            borderWidth: '2px',
            borderColor: theme.palette.grayscale[200],
            borderStyle: 'solid',
          },

          '&.Mui-disabled .MuiSwitch-thumb': {
            backgroundColor: theme.palette.grayscale[200],
            boxShadow: 'none',
          },
        }),

        thumb: () => ({
          boxSizing: 'border-box',
          width: 16,
          height: 16,
          backgroundColor: theme.palette.grayscale[400],
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          boxShadow: 'none',
        }),
        track: ({ theme }) => ({
          borderRadius: 26 / 2,
          backgroundColor: '#fff',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          borderWidth: '1px',
          borderColor: theme.palette.grayscale[400],
          borderStyle: 'solid',
        }),
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          '& > .MuiMenuItem-root': {
            padding: '4px 16px',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        standard: {
          '& > .MuiMenuItem-root': {
            paddingLeft: 0,
          },
        },
        outlined: {
          lineHeight: 1.2,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.button,
          textTransform: 'none',
          height: '48px',
          borderRadius: '8px',
          boxShadow: 'none',
          lineHeight: 0,
          padding: '6px 16px',

          '&:hover': {
            boxShadow: 'none',
          },

          [theme.breakpoints.down('lg')]: {
            height: '40px',
          },
          [theme.breakpoints.down('sm')]: {
            height: '24px',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            background: theme.palette.brand.orange,
            color: '#fff',

            '& .MuiButton-endIcon, .MuiButton-startIcon': {
              'svg > path': {
                fill: '#fff',
              },
            },

            '&:hover': {
              backgroundColor: theme.palette.brand.orangeHover,
            },

            '&.Mui-disabled': {
              backgroundColor: theme.palette.grayscale[200],
              color: theme.palette.grayscale[300],
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: ({ theme }) => ({
            background: theme.palette.brand.orange,
            color: '#fff',

            '& .MuiButton-endIcon, .MuiButton-startIcon': {
              'svg > path': {
                fill: '#fff',
              },
            },

            '&:hover': {
              backgroundColor: theme.palette.brand.orangeHover,
            },

            '&.Mui-disabled': {
              backgroundColor: theme.palette.grayscale[200],
              color: theme.palette.grayscale[300],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            background: '#fff',
            color: theme.palette.brand.orange,
            border: `2px solid ${theme.palette.brand.orange}`,

            '& .MuiButton-endIcon, .MuiButton-startIcon': {
              'svg > path': {
                fill: theme.palette.brand.orange,
              },
            },

            '&:hover': {
              backgroundColor: theme.palette.brand.orangeLight,
              border: `2px solid ${theme.palette.brand.orange}`,
            },

            '&.Mui-disabled': {
              backgroundColor: theme.palette.grayscale[200],
              color: theme.palette.grayscale[300],
              border: `1px solid ${theme.palette.grayscale[400]}`,
              '& .MuiButton-endIcon, .MuiButton-startIcon': {
                'svg > path': {
                  fill: theme.palette.grayscale[300],
                },
              },
            },
          }),
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: ({ theme }) => ({
            background: '#fff',
            color: theme.palette.brand.orange,

            '& .MuiButton-endIcon, .MuiButton-startIcon': {
              'svg path': {
                fill: theme.palette.brand.orange,
              },
            },

            '&:hover': {
              backgroundColor: theme.palette.brand.orangeLight,
            },

            '&.Mui-active': {
              border: `2px solid ${theme.palette.brand.orange}`,
            },

            '&.Mui-disabled': {
              backgroundColor: theme.palette.grayscale[100],
              color: theme.palette.grayscale[300],

              '& .MuiButton-endIcon, .MuiButton-startIcon': {
                'svg > path': {
                  fill: theme.palette.grayscale[300],
                },
                'svg > g > path': {
                  fill: theme.palette.grayscale[300],
                },
              },
            },
          }),
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: () => ({
          borderRadius: '8px',
        }),
      },

      variants: [
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.brand.purple,

            '&:hover': {
              backgroundColor: 'rgba(58, 25, 151, 0.1)',
            },

            '&:disabled svg, &:disabled path': {
              fill: theme.palette.grayscale[300],
            },
          }),
        },
        {
          props: { color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.brand.orange,

            '&:hover': {
              backgroundColor: 'rgba(255, 81, 0, 0.1)',
            },

            '&:disabled svg, &:disabled path': {
              fill: theme.palette.grayscale[300],
            },
          }),
        },

        {
          props: { color: 'default' },
          style: ({ theme }) => ({
            color: theme.palette.grayscale[400],

            '&:hover': {
              backgroundColor: 'rgba(125, 125, 125, 0.1)',
            },
          }),
        },
      ],
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          boxShadow: 'none',
          fontSize: '17px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          fontWeight: 600,
          textTransform: 'none',
          paddingRight: 0,
          paddingLeft: 0,
          marginRight: '24px',

          '&.Mui-selected': {
            color: theme.palette.grayscale[500],
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: '#333',
          background: '#fff',
          border: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,

          '.MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            display: 'none',
          },

          '& .MuiDialogActions-root': {
            justifyContent: 'center',
            '& .MuiButton-root': {
              width: '100%',
            },
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '15px',
          letterSpacing: '0.25px',
          whiteSpace: 'nowrap',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '4px 16px',
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 'none',

          '& .MuiTablePagination-root': {
            '& .MuiTablePagination-toolbar': {
              ...theme.typography.caption,
              paddingRight: '32px',
              color: '#7D7D7D',
            },
          },
        }),
        columnHeader: {
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#7d7d7d',
            fontWeight: 300,
          },

          '&:first-of-type': {
            paddingLeft: '32px',
          },
          '&:last-of-type': {
            paddingRight: '32px',
          },

          ':focus-within': {
            outline: 'none',
          },

          '& .MuiCheckbox-root': {
            transform: 'translateX(-3px)',
          },
        },

        cell: {
          '&:first-of-type': {
            paddingLeft: '32px',
          },
          '&:last-of-type': {
            paddingRight: '32px',
          },

          ':focus-within': {
            outline: 'none',
          },
        },

        row: {
          cursor: 'pointer',
          '& .MuiDataGrid-cell[data-field=__detail_panel_toggle__]': {
            paddingLeft: '10px',
          },
        },
        columnSeparator: {
          display: 'none',
        },

        detailPanel: {
          backgroundColor: 'transparent',
        },

        groupingCriteriaCellToggle: {
          display: 'none',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAutocomplete-inputRoot': {
            padding: '4.5px 14px',

            [theme.breakpoints.down('lg')]: {
              padding: '0.5px 14px',
            },
          },
        }),
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#F2F2F2',
        },
      },
    },
  },
});
