import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { revertAll } from '../actions';

type DrawerState = {
  isDrawerOpen: boolean;
  drawerItemOpen: string;
};

const initialState: DrawerState = {
  isDrawerOpen: true,
  drawerItemOpen: '',
};

const drawerSlice = createSlice({
  name: 'drawerSlice',
  initialState,
  reducers: {
    drawerOpenToggle: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },

    drawerClose: (state) => {
      state.isDrawerOpen = false;
    },

    drawerItemOpen: (state, { payload }: PayloadAction<string>) => {
      state.drawerItemOpen = payload;
    },
  },

  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const selectDrawerOpen = (state: RootState) => state.drawer.isDrawerOpen;
export const selectDrawerItemOpen = (state: RootState) => state.drawer.drawerItemOpen;

export const { drawerOpenToggle, drawerItemOpen, drawerClose } = drawerSlice.actions;

export default drawerSlice.reducer;
