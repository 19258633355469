import { Box, Button, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { ReactComponent as CleantrackerSvg } from 'assets/svg/login/cleantracker-logo.svg';
import heroPng from 'assets/png/login/hero.png';
import { ReactComponent as Eye } from 'assets/svg/common/eye-line.svg';
import { ReactComponent as EyeOff } from 'assets/svg/common/eye-off-line.svg';
import { ReactComponent as ArrowIcon } from 'assets/svg/common/arrow-icon.svg';
import { useState } from 'react';
import TextField from 'modules/common/components/TextField';
import { useLazyGetUserInfoQuery, useLoginMutation } from 'store/services/auth';

export const Login = () => {
  const [loginRequest] = useLoginMutation();
  const [getUserInfo] = useLazyGetUserInfoQuery();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    const data = {
      userName: email,
      password,
    };

    const res = await loginRequest(data).unwrap();
    sessionStorage.setItem('token', res.token);
    sessionStorage.setItem('refreshToken', res.refreshToken);
    await getUserInfo().unwrap();
  };

  return (
    <Grid container height='100vh'>
      <Grid item lg={6} display={{ xs: 'none', lg: 'block' }} bgcolor='#CCDBDE'>
        <Stack height='100%' justifyContent='center'>
          <Box display='flex' justifyContent='center' alignItems='center' height='65vh'>
            <img src={heroPng} alt='hero' height='100%' />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6} alignSelf='center' px={2}>
        <Box maxWidth='400px' mx='auto' height='100%' textAlign='center'>
          <Box mb={4} maxWidth={300} mx='auto'>
            <CleantrackerSvg />
          </Box>
          <Typography component='div' variant='h2' sx={{ color: '#333', mb: 1.5 }}>
            Log in to Cleantracker
          </Typography>
          <Typography
            component='div'
            variant='subtitle1'
            sx={{ fontWeight: 600, color: '#7D7D7D', mb: 4 }}
          >
            Welcome back! Login with your data.
          </Typography>

          <Box width='100%' sx={{ mb: 2 }}>
            <TextField
              fullWidth
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
              label='Username'
            />
          </Box>
          <Box width='100%' sx={{ mb: 6 }}>
            <TextField
              id='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)} color='default'>
                      {showPassword ? <Eye /> : <EyeOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Button onClick={handleLogin} endIcon={<ArrowIcon />} sx={{ width: '100%' }}>
            Log in
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
