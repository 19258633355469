import { skipToken } from '@reduxjs/toolkit/dist/query';
import { USER_ROLES } from 'modules/common/constants/userRoles';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useGetUserInfoQuery } from 'store/services/auth';

const PublicRoute = (props: RouteProps) => {
  const token = sessionStorage.getItem('token');
  const { data: user } = useGetUserInfoQuery(token ? undefined : skipToken);

  if (user) {
    if (user.userRole === USER_ROLES.SUPPORT_ADMIN) {
      return (
        <Redirect
          to={{
            pathname: '/support-admin',
          }}
        />
      );
    }

    if (user.userRole === USER_ROLES.SYSTEM_ADMIN) {
      return (
        <Redirect
          to={{
            pathname: '/system-admin',
          }}
        />
      );
    }

    if (user.userRole === USER_ROLES.PORTFOLIO_CUSTOMER) {
      return (
        <Redirect
          to={{
            pathname: '/portfolio-customer',
          }}
        />
      );
    }
    if (user.userRole === USER_ROLES.PORTFOLIO_USER) {
      return (
        <Redirect
          to={{
            pathname: '/portfolio-user',
          }}
        />
      );
    }
  }

  return <Route {...props} />;
};

export default PublicRoute;
