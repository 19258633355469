import { createSlice } from '@reduxjs/toolkit';
import { IAuth } from 'interfaces/responses';
import { revertAll } from 'store/actions';
import { RootState } from '../index';

type AuthState = {
  userInfo: IAuth | null;
};

const initialState: AuthState = {
  userInfo: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const selectUserInfo = (state: RootState) => state.auth.userInfo;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
