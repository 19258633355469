import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UserRoles } from 'modules/common/constants/userRoles';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useGetUserInfoQuery } from 'store/services/auth';

interface IPrivateRouteProps extends RouteProps {
  allowedRole?: UserRoles;
}
const PrivateRoute = (props: IPrivateRouteProps) => {
  const token = sessionStorage.getItem('token');

  const { data: user } = useGetUserInfoQuery(token ? undefined : skipToken);

  if (!token || !user) {
    <Redirect
      to={{
        pathname: '/login',
      }}
    />;
  }

  if (token && !user) {
    return null;
  }

  if (props.allowedRole) {
    return user?.userRole === props.allowedRole ? <Route {...props} /> : <Redirect to='/' />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
